.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.main-bar {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  display:block;
  height:48px;
}

.hero-image {
  background-image: url("../src/resources/home.png");
  min-height: 80vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  position: relative;
}

.plant-image {
  background-image: url("../src/resources/plants.png");
  min-height: 75vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  position: relative;
}

@media (orientation: landscape) {
  .inputbox {
  }
}

@media (orientation: portrait) {
  .inputbox {
    width: 75%;
  }
}