.todos-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	position: relative;
}

.todos-content {
	flex: 1;
	overflow-y: scroll;
}

.todos-header {
	border-bottom: 1px solid #ddd;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 16px;
}

.todos-loader {
	align-items: center;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}
